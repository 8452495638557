<template>
    <page>
        <page-head>
            <app-button property="success" icon="icon-add" @click="createModal">
                {{ translate('button.Add') }}
            </app-button>
        </page-head>

        <page-body>
            <data-grid
                :data-source="partners"
                :columns="columns"
                :action-column-width="120"
                :action-column-text="translate(translateKey + '.Label.Action')"
            >
                <div slot-scope="list" slot="photoTemplate">
                    <img :src="list.row.photo" alt="" class="w-full h-full object-cover">
                </div>
                <div slot="actionSlot" slot-scope="list">
                    <app-button
                    
                        @click="createModal(list.row.form)"
                        :sm="true"
                        :title="translate('button.Edit')"
                        icon="icon-edit-2"
                        property="primary"
                    />

                    <app-button
                    
                        :sm="true"
                        @click="action(list.row, 'active')"
                        :title="list.row.action.active ? translate('button.DeActivate') : translate('button.Activate')"
                        :icon="!list.row.action.active ? 'icon-eye-off' : 'icon-eye'"
                        property="success"
                    />

                    <app-button
            
                        :sm="true"
                        @click="remove(list.row.id)"
                        :title="translate('button.Delete')"
                        icon="icon-trash-2"
                        property="danger"
                    />

                </div>
            </data-grid>
        </page-body>

        <modal :id="modalId" size="md">
            <modal-head>
                <modal-title>{{ currentPage.title }}</modal-title>
            </modal-head>
            <modal-body v-if="modelShow">
                <form @submit.prevent="save">
                    <grid>
                        <!-- <form-group
                            :label="translate(translateKey + '.Label.Name') + (appLanguages.length > 1 ? ' ('+lang.name+')' : '')"
                            :name="'translates.'+lang.code+'.name'"
                            v-for="(lang, index) in appLanguages"
                            :key="index"
                        >
                            <form-text v-model="form.translates[lang.code].name"/>
                        </form-group> 

                         <form-group
                            :label="translate(translateKey + '.Label.Content') + (appLanguages.length > 1 ? ' ('+lang.name+')' : '')"
                            :name="'translates.'+lang.code+'.Content'"
                            v-for="(lang, index) in appLanguages"
                            :key="'content'+index"
                        >
                            <form-html-editor type="simple" v-model="form.translates[lang.code].content"/>
                        </form-group> -->

                        <form-group :label="'Adı'" name="name">
                            <form-text v-model.lazy="form.name"/>
                        </form-group>

                        <form-group :label="'Soyadı'" name="surname">
                            <form-text v-model.lazy="form.surname"/>
                        </form-group>

                        <form-group :label="'Işdən çıxma səbəbi'" name="message">
                            <form-text v-model.lazy="form.message"/>
                        </form-group>

                    

                        <app-button class="justify-center" property="success" type="submit">
                            {{ translate('button.Save') }}
                        </app-button>
                    </grid>
                </form>
            </modal-body>
        </modal>
    </page>
</template>

<script>
/*
 * Import Components
 * */
 import { mapActions, mapState } from 'vuex';

const modalId = 'createModal';
const translateKey = 'crm.Inventory';

export default {
    name: "PartnersIndex",
    data() {
        return {
            translateKey,
            modalId,
            modelShow: false,
            columns: [
                {
                    caption: "Adı",
                    dataField: 'name',
                    show: true,
                    width: 320,
                },
                {
                    caption: "Soyadı",
                    dataField: 'surname',
                    show: true,
                    width: 320,
                },
                {
                    caption: "Işdən cçıxma səbəbi",
                    dataField: 'message',
                    show: true,
                    width: 320,
                }
            ],
            form: {
                name: '',
                surname: '',
                message: ''
            }
        }
    },
    computed: {
        ...mapState('PartnersStore', ['partners']),
        permission() {
            return this.currentPage.permission;
        }
    },
    methods: {
        ...mapActions('PartnersStore', ['getPartners', 'setPartner', 'actionPartner', 'deletePartner']),
        /*
         * Form Create
         * */
        formCreate(item = {}) {
            const form = {
                id: item.id || null,
                name: item.name || null,
                surname: item.surname || null,
                message: item.message || null, 
                
            }
            this.form = form;
            this.modelShow = true;
        },
        /*
         * Create Modal
         * */
        createModal(item = {}) {
            this.modal(this.modalId)
            this.modelShow = false;
            this.resetError();
            this.formCreate(item);
        },
        /*
         * Remove
         * */
        remove(id) {
            this.alert().then(r => this.deletePartner(id).then(r => this.getPartners()))
        },
        /*
         * Action
         * */
        action(item, type) {
            let action = item.action[type] ? 0 : 1;
            this.actionPartner({id: item.id, type, action}).then(r => this.getPartners())
        },
        /*
         * Save
         * */
        save() {
            this.setPartner(this.form)
            .then(r => {
                if (r) {
                    this.modal(this.modalId);
                    this.getPartners();
                    console.log(this.modalId);
                }
            })
        }
    },
    created() {
        this.getPartners();
        this.showPartnerDetails();
    }
}
</script>

<style scoped>

</style>